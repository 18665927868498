.secondary-custom {
  font-size: 12px !important;
  font-weight: 700;
  background-color: #dceefb;
  letter-spacing: 1px;
  color: #003359 !important;
  padding: 5px 8px;
  text-transform: uppercase;
}
.info-custom {
  font-size: 12px !important;
  font-weight: 700;
  background-color: #e1effd;
  letter-spacing: 1px;
  color: #0365e8 !important;
  padding: 5px 8px;
  text-transform: uppercase;
}
.success-custom {
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #e6f5ed;
  letter-spacing: 1px;
  color: #00612d !important;
  padding: 5px 8px;
  text-transform: uppercase;
}
.warning-custom {
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #fffbea;
  letter-spacing: 1px;
  color: #cb6e17 !important;
  padding: 5px 8px;
  text-transform: uppercase;
}
.danger-custom {
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #ffeeee;
  letter-spacing: 1px;
  color: #911111 !important;
  padding: 5px 8px;
  text-transform: uppercase;
}

.table-custom {
  position: absolute;
  top: 40px;
  left: 37px;
  width: 296px;
}

.dropdown-search {
  border-radius: 0px,
}

.container-search {
  width: 296px;
  height: 94px;
  text-align: center;
}
#input-group-dropdown-2 {
  min-width: 100px;
  text-align: center;
  border-radius: 0px,
}

.w-400 {
  width: 350px !important;
}

.btn-link-warning {
  color: red !important;
}
.icon {
  background-color: hsl(0, 0%, 80%);
  padding: 1rem;
}

.edit-icon {
  color: #6c757d;
}

.small-icon {
  background-color: hsl(0, 0%, 80%);
  padding: 0.8rem;
}